import * as React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import { cn } from '@ui/helpers/utils';
import { Button } from '@ui/atoms/design-system';
import Autoplay from 'embla-carousel-autoplay';

// Create a context for the carousel
const CarouselContext = React.createContext(null);

// Custom hook to use the carousel context
function useCarousel() {
    const context = React.useContext(CarouselContext);

    if (!context) {
        console.error('useCarousel must be used within a <Carousel />');
    }

    return context;
}

// Carousel component
const Carousel = React.forwardRef(
    (
        {
            orientation = 'horizontal', // Carousel orientation: 'horizontal' or 'vertical'
            opts,
            setApi,
            plugins = [],
            count,
            className,
            autoPlay = true, // Enable autoplay, by default it's true
            delay = 5000, // Autoplay delay in milliseconds, default is 5000 (5sec)
            children,
            ...props
        },
        ref
    ) => {
        const autoplayRef = React.useRef(
            Autoplay({
                delay,
            })
        );

        const dragEnabled = count > 1;

        const allPlugins = [
            autoPlay ? autoplayRef.current : null,
            ...plugins,
        ].filter(Boolean);

        const [carouselRef, api] = useEmblaCarousel(
            {
                ...opts,
                axis: orientation === 'horizontal' ? 'x' : 'y',
                watchDrag: dragEnabled,
            },
            allPlugins
        );

        const [canScrollPrev, setCanScrollPrev] = React.useState(false);
        const [canScrollNext, setCanScrollNext] = React.useState(false);

        const onSelect = React.useCallback((api) => {
            if (!api) {
                return;
            }

            setCanScrollPrev(api.canScrollPrev());
            setCanScrollNext(api.canScrollNext());
        }, []);

        const resetAutoplay = React.useCallback(() => {
            if (autoplayRef.current) {
                autoplayRef.current.reset();
            }
        }, []);

        const scrollPrev = React.useCallback(() => {
            resetAutoplay();
            api?.scrollPrev();
        }, [api]);

        const scrollNext = React.useCallback(() => {
            resetAutoplay();
            api?.scrollNext();
        }, [api]);

        const handleKeyDown = React.useCallback(
            (event) => {
                if (event.key === 'ArrowLeft') {
                    event.preventDefault();
                    scrollPrev();
                } else if (event.key === 'ArrowRight') {
                    event.preventDefault();
                    scrollNext();
                }
            },
            [scrollPrev, scrollNext]
        );

        React.useEffect(() => {
            if (!api || !setApi) {
                return;
            }

            setApi(api);
        }, [api, setApi]);

        React.useEffect(() => {
            if (!api) {
                return;
            }

            onSelect(api);
            api.on('reInit', onSelect);
            api.on('select', onSelect);

            return () => {
                api?.off('select', onSelect);
            };
        }, [api, onSelect]);

        return (
            <CarouselContext.Provider
                value={{
                    carouselRef,
                    api: api,
                    opts,
                    orientation:
                        orientation ||
                        (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
                    scrollPrev,
                    scrollNext,
                    canScrollPrev,
                    canScrollNext,
                    resetAutoplay,
                }}
            >
                <div
                    ref={ref}
                    onKeyDownCapture={handleKeyDown}
                    className={cn('relative', className)}
                    role="region"
                    aria-roledescription="carousel"
                    {...props}
                >
                    {children}
                </div>
            </CarouselContext.Provider>
        );
    }
);
Carousel.displayName = 'Carousel';

// CarouselContent component
const CarouselContent = React.forwardRef(({ className, ...props }, ref) => {
    const { carouselRef, orientation } = useCarousel();

    return (
        <div ref={carouselRef} className="overflow-hidden">
            <div
                ref={ref}
                className={cn(
                    'flex',
                    orientation === 'horizontal' ? '-ml-0 ' : '-mt-4 flex-col',
                    className
                )}
                {...props}
            />
        </div>
    );
});
CarouselContent.displayName = 'CarouselContent';

// CarouselItem component
const CarouselItem = React.forwardRef(({ className, ...props }, ref) => {
    const { orientation } = useCarousel();

    return (
        <div
            ref={ref}
            role="group"
            aria-roledescription="slide"
            className={cn(
                'min-w-0 shrink-0 grow-0 basis-full',
                orientation === 'horizontal' ? 'first:ml-4' : 'pt-4',
                className
            )}
            {...props}
        />
    );
});
CarouselItem.displayName = 'CarouselItem';

// CarouselPrevious component
const CarouselPrevious = React.forwardRef(
    ({ className, variant = 'outline', size = 'icon', ...props }, ref) => {
        const { orientation, scrollPrev, canScrollPrev } = useCarousel();

        return (
            <Button
                ref={ref}
                variant={variant}
                size={size}
                className={cn(
                    'absolute h-8 w-8 rounded-full',
                    orientation === 'horizontal'
                        ? '-left-12 top-1/2 -translate-y-1/2'
                        : '-top-12 left-1/2 -translate-x-1/2 rotate-90',
                    className
                )}
                disabled={!canScrollPrev}
                onClick={scrollPrev}
                {...props}
            >
                <ArrowLeftIcon className="w-4 h-4" />
                <span className="sr-only">Previous slide</span>
            </Button>
        );
    }
);
CarouselPrevious.displayName = 'CarouselPrevious';

// CarouselNext component
const CarouselNext = React.forwardRef(
    ({ className, variant = 'outline', size = 'icon', ...props }, ref) => {
        const { orientation, scrollNext, canScrollNext } = useCarousel();

        return (
            <Button
                ref={ref}
                variant={variant}
                size={size}
                className={cn(
                    'absolute h-8 w-8 rounded-full',
                    orientation === 'horizontal'
                        ? '-right-12 top-1/2 -translate-y-1/2'
                        : '-bottom-12 left-1/2 -translate-x-1/2 rotate-90',
                    className
                )}
                disabled={!canScrollNext}
                onClick={scrollNext}
                {...props}
            >
                <ArrowRightIcon className="w-4 h-4" />
                <span className="sr-only">Next slide</span>
            </Button>
        );
    }
);
CarouselNext.displayName = 'CarouselNext';

// CarouselDots component
const CarouselDots = React.forwardRef((props, ref) => {
    const { api, resetAutoplay } = useCarousel();
    const [updateState, setUpdateState] = React.useState(false);
    const [highlightStyle, setHighlightStyle] = React.useState({});

    const toggleUpdateState = React.useCallback(
        () => setUpdateState((prevState) => !prevState),
        []
    );
    const dotsRef = React.useRef(null);

    React.useEffect(() => {
        if (api) {
            api.on('select', toggleUpdateState);
            api.on('reInit', toggleUpdateState);

            return () => {
                api.off('select', toggleUpdateState);
                api.off('reInit', toggleUpdateState);
            };
        }
    }, [api, toggleUpdateState]);

    React.useEffect(() => {
        if (dotsRef.current && api) {
            const currentSlide = api.selectedScrollSnap();
            const dot = dotsRef.current.children[currentSlide];

            if (dot) {
                const { offsetLeft, offsetWidth } = dot;
                setHighlightStyle({
                    transform: `translateX(${offsetLeft}px)`,
                    width: `${offsetWidth}px`,
                });
            }
        }
    }, [updateState, api]);

    const numberOfSlides = api?.scrollSnapList().length || 0;

    if (numberOfSlides > 1) {
        return (
            <div
                ref={ref}
                className={`relative flex justify-center ${props.className}`}
            >
                <div ref={dotsRef} className="relative flex">
                    {Array.from({ length: numberOfSlides }, (_, i) => (
                        <div
                            key={i}
                            className={`mx-1 h-2 w-2 border-[0.5px] border-primary-500 rounded-full p-0 cursor-pointer`}
                            aria-label={`Go to slide ${i + 1}`}
                            onClick={() => {
                                api?.scrollTo(i);
                                resetAutoplay();
                            }}
                        ></div>
                    ))}
                    <div
                        className="absolute bottom-0 w-2 h-2 transition-transform duration-300 rounded-full bg-primary-500"
                        style={{
                            ...highlightStyle,
                            transition:
                                'transform 0.5s ease-in-out, width 0.5s ease-in',
                        }}
                    />
                </div>
            </div>
        );
    } else {
        return <></>;
    }
});
CarouselDots.displayName = 'CarouselDots';

export {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselPrevious,
    CarouselNext,
    CarouselDots,
};

// USAGE EXAMPLE
// function MyCarousel() {
//     return (
//         <Carousel autoPlay={true} delay={2000} className="custom-class">
//             <CarouselContent className="custom-class">
//                 <CarouselItem className="slide">Slide 1</CarouselItem>
//                 <CarouselItem className="slide">Slide 2</CarouselItem>
//                 <CarouselItem className="slide">Slide 3</CarouselItem>
//             </CarouselContent>
//             <CarouselPrevious className="custom-class" />
//             <CarouselNext className="custom-class" />
//             <CarouselDots className="custom-class" />
//         </Carousel>
//     );
// }
